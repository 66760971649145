<template>
  <div class="div1">
    <div style="text-align: center">
      <p>{{ nowDate }} 视频监控问题上报</p>
    </div>
    <div style="width: 100vh;margin: auto;padding-bottom: 100px">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple">
          <el-select v-model="value" clearable placeholder="请选择" @change="bdstid">
            <el-option
                v-for="item in options"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"
            >
            </el-option>
          </el-select>
        </div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-light">
          <el-select v-model="value1" clearable placeholder="请选择">
            <el-option
                v-for="item1 in options1"
                :key="item1.bdst"
                :label="item1.operatingpoint"
                :value="item1.operatingpoint"
            >
            </el-option>
          </el-select>
        </div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">
          <el-select v-model="value3" clearable placeholder="请选择">
            <el-option
                v-for="item3 in options3"
                :key="item3.value"
                :label="item3.label"
                :value="item3.value"
            >
            </el-option>
          </el-select>
        </div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">
          <el-select v-model="value2" clearable placeholder="请选择">
            <el-option
                v-for="item2 in options2"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
            >
            </el-option>
          </el-select>
        </div></el-col>
      </el-row>
    </div>
    <div style="width: 100vh;margin: auto;padding-bottom: 100px">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple">
          <el-select v-model="value4" clearable placeholder="请选择" @change="bdstid2">
            <el-option
                v-for="item in options"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"

            >
            </el-option>
          </el-select>
        </div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-light">
          <el-input v-model="input" placeholder="请输入" />
        </div></el-col>
      </el-row>
    </div>
    <div style="float: right;margin-right: 100px">
      <el-button type="primary" @click="sub">提交</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "addquest",
  data(){
    return{
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      options:[],
      options1:[],
      options2:[{
        value: '掉线',
        label: '掉线',
      },
        {
          value: '云台操作困难',
          label: '云台操作困难',
        },
        {
          value: '画面模糊',
          label: '画面模糊',
        }],
      options3:[{
        value: '洞口',
        label: '洞口',
      },
        {
          value: '掌子面',
          label: '掌子面',
        },
        {
          value: '仰拱',
          label: '仰拱',
        },
        {
          value: '二衬',
          label: '二衬',
        },
        {
          value: '仰坡',
          label: '仰坡',
        },
        {
          value: '桥墩-1',
          label: '桥墩-1',
        },
        {
          value: '桥墩-2',
          label: '桥墩-2',
        }
      ],
      value: '',
      value1:'',
      value2:'',
      value3:'',
      value4:'',
      input:'',
      bdstids: '',
      username: '',
      apms: ''
    }
  },
  created() {
    this.setNowTimes();
    this.axios.get("/bdst/getbdst", (response) => {
      this.options = response.obj;
    })
  },
  mounted() {

  },
  methods: {
    setNowTimes() {
      let myDate = new Date()
      // console.log(myDate)
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min
      this.nowWeek = week
    },
    bdstid(value) {
      this.value1 = '';
      this.value4 = '';
      this.input = '';
      this.bdstids = value;
      this.axios.post("/bdst/getopart", (response) => {
        this.options1 = response.obj
      },{
        bdst:value
      })
    },
    bdstid2(value){
      this.bdstids = value;
      this.value = '';
      this.value1 = '';
      this.value2 = '';
      this.value3 = '';
    },
    sub(){
      var question;
      this.setNowTimes();
      if (this.nowTime.slice(0,2) >= 0 && this.nowTime.slice(0,2) <= 12){
        this.apms = 0;
      }else{
        this.apms = 1;
      }
      if (this.value === ''){
        question = '';
        question = this.input
        this.axios.post("/userinfo/getuser", (response) => {
          this.username = response.obj.name;
          if (this.username != null){
            this.axios.post("/videoquestion/savev", (response) => {
              if (response.obj){
                this.value = '';
                this.value1 = '';
                this.value2 = '';
                this.value3 = '';
                this.value4 = '';
                this.input = '';
                ElMessage({
                  message: '提交成功',
                  type: 'success',
                })
              }else{
                ElMessage.error('提交失败，请联系管理员')
              }
            },{
              operatingpoint: ' ',
              question: question,
              time: this.nowDate + ' ' + this.nowTime,
              username: this.username,
              bdst: this.bdstids,
              apm: this.apms,
              status: 0
            })
          }
        })
      }
      if (this.value4 === ''){
        question = '';
        question = this.value3 + this.value2;
        this.axios.post("/userinfo/getuser", (response) => {
          this.username = response.obj.name;
          if (this.username != null){
            this.axios.post("/videoquestion/savev", (response) => {
              if (response.obj){
                this.setNowTimes();
                this.value = '';
                this.value1 = '';
                this.value2 = '';
                this.value3 = '';
                this.value4 = '';
                this.input = '';
                ElMessage({
                  message: '提交成功',
                  type: 'success',
                })
              }else{
                ElMessage.error('提交失败，请联系管理员')
              }
            },{
              operatingpoint: this.value1,
              question: question,
              time: this.nowDate + ' ' + this.nowTime,
              username: this.username,
              bdst: this.bdstids,
              apm: this.apms,
              status: 0
            })
          }
        })
       }
    }
  }
}
</script>

<style scoped>

</style>
