<template>
  <div style="padding: 0;margin: 0">
    <div style="margin: 0 auto;width: 95%;background-color: white;padding-top: 30px">
      <el-tabs v-model="activeName"  tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="上报监控问题" name="a" style="margin-left: 50px;margin-bottom: 100px"><Addquest></Addquest></el-tab-pane>
        <el-tab-pane label="查询监控问题" name="b" style="margin-left: 50px;margin-bottom: 100px"><Selectque></Selectque></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Addquest from '@/views/cream/addquest';
import Selectque from '@/views/cream/selectque';
var that;
import {ElMessage, ElMessageBox} from 'element-plus';
export default {
  name: "indexheader",
  components: {Selectque, Addquest},
  data() {
    return {

      activeName: 'a',
      username: '',
    }
  },
  created() {
    that = this;
  },
  methods: {

    openadd(){
      this.addque = true;
      this.selque  =  false;
    },
    opensel(){
      this.addque = false;
      this.selque  =  true;
    }
  }
}
</script>

<style scoped>
</style>
