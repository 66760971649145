<template>
  <div>
    <el-container>
      <el-main>

      </el-main>
    </el-container>
  </div>

</template>

<script>
import Addquest from '@/views/cream/addquest'
export default {
  name: "indexmain"
}
</script>

<style scoped>
.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 160px;
}
</style>
