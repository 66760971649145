<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-date-picker
          v-model="value2"
          type="date"
          placeholder="选择日期"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          style="margin-right: 50px"
      >
      </el-date-picker>
      <el-button type="primary" @click="seleque">查询</el-button>
      <el-button type="primary" @click="selequeam">查询当日上午</el-button>
      <el-button type="primary" @click="selequepm">查询当日下午</el-button>
    </div>
    <div>
      <el-table :data="tableData" :span-method="mergedata" >
        <el-table-column prop="bdst" label="标段" width="150" />
        <el-table-column prop="operatingpoint" label="工点" width="200" />
        <el-table-column prop="question" label="问题" width="150" />
        <el-table-column prop="time" label="巡查时间" width="180"/>
        <el-table-column prop="username" label="巡查人员" width="180"/>
        <el-table-column prop="cause" label="备注" width="150"/>
        <el-table-column label="是否整改" width="130">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 0" type="danger" effect="dark" size="mini">未整改</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success" effect="dark" size="mini">已整改</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="mini" plain @click="checkok(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="dialogFormVisible" title="监控问题修改">
      <el-form :model="form">
        <div style="display: flex">
          <el-form-item label="标段" >
            <el-input v-model="form.bdst" disabled autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 100px" label="工点" >
            <el-input v-model="form.operatingpoint" disabled autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="存在问题" >
          <el-input v-model="form.question" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="巡查时间">
          <el-input v-model="form.time" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="巡查人员" >
          <el-input v-model="form.username" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="原因备注" >
          <el-input v-model="form.cause" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否整改" >
          <el-radio-group v-model="radio1">
            <el-radio :label="0">未整改</el-radio>
            <el-radio :label="1">已整改</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveque">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "selectque",
  data(){
    return{
      value2:'',
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      tableData: [],
      dialogFormVisible: false,
      radio1: 0,
      form: {
        id: '',
        bdst: '',
        operatingpoint: '',
        question: '',
        time: '',
        username: '',
        cause: '',
        status: ''
      },
      apms: ''
    }
  },
  created() {
    this.setNowTimes();
    this.value2 = this.nowDate
  },
  methods:{
    setNowTimes() {
      let myDate = new Date()
      // console.log(myDate)
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min
      this.nowWeek = week
    },
    seleque(){
      this.axios.post("/videoquestion/seleque", (response)=>{
        this.tableData = response.obj
        let arr = []
        this.tableData.forEach(ele => {
          let firstIndex = this.tableData.findIndex(item => {
            return item.bdst === ele.bdst   // 当category相同的时候，返回第一个相同的Index 赋值给 firstIndex
          })
          if (arr.findIndex(item => {
            return item.firstIndex === firstIndex
          }) === -1) {
            arr.push({
              length: this.tableData.filter(item => {
                return item.bdst === ele.bdst    //利用数组的filter方法，过滤出相同category的数组的长度。数组长度-即为跨多少行
              }).length,
              firstIndex: firstIndex    // firstIndex 返回的是第一个catergory就满足的第一个Index,即为rowIndex开始于第几行。
            })
          }
        })
        this.indexInfoList = arr
        for (let i = 0; i < arr.length; i++){
          this.axios.post('/bdst/getowner', (response) => {
            if (this.tableData[arr[i].firstIndex].bdst === '00'){
              this.tableData[arr[i].firstIndex].bdst = '中心实验室' + ':' + response.obj;
            } else {
              var bd = this.tableData[arr[i].firstIndex].bdst;
              var bd1 = bd.slice(0,1);
              var bd2 = bd.slice(1,2);
              var bdqc = bd1 + '标' + bd2 + '工区' + ':' + response.obj;
              this.tableData[arr[i].firstIndex].bdst = bdqc;
            }
          },{
            bdst: this.tableData[arr[i].firstIndex].bdst
          })
         }
      },{
        time: this.value2
      })
    },
    mergedata({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        let index = this.indexInfoList.findIndex(item => {  //遍历数组
          return item.firstIndex === rowIndex
        })
        if (index > -1) {
          return {
            rowspan: this.indexInfoList[index].length,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    checkok(row){
      this.radio1 = row.status;
      this.dialogFormVisible = true;
      this.form = row;
      console.log(this.radio1)
    },
    saveque(){
      if (this.form.cause === null){
        this.form.cause = ' '
      }
      this.setNowTimes();
      if (this.nowTime.slice(0,2) >= 0 && this.nowTime.slice(0,2) <= 12){
        this.apms = 0;
      }else{
        this.apms = 1;
      }
      var a = this.form.bdst.slice(0,1);
      var b = this.form.bdst.slice(2,3);
      var ab = a + b;
      this.form.bdst = ab;
      this.axios.post('/videoquestion/updateque', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
          if (this.apms === 0){
            this.selequeam();
          }
          if (this.apms === 1){
            this.selequepm();
          }
          this.dialogFormVisible = false;
        }else {
          ElMessage({
            message: '修改失败，请联系管理员',
            type: 'warning',
          })
        }
      },{
        id: this.form.id * 1,
        status : this.radio1,
        cause: this.form.cause
      })
    },
    selequeam(){
      this.axios.post("/videoquestion/selectam", (response)=>{
        this.tableData = response.obj
        let arr = []
        this.tableData.forEach(ele => {
          let firstIndex = this.tableData.findIndex(item => {
            return item.bdst === ele.bdst   // 当category相同的时候，返回第一个相同的Index 赋值给 firstIndex
          })
          if (arr.findIndex(item => {
            return item.firstIndex === firstIndex
          }) === -1) {
            arr.push({
              length: this.tableData.filter(item => {
                return item.bdst === ele.bdst    //利用数组的filter方法，过滤出相同category的数组的长度。数组长度-即为跨多少行
              }).length,
              firstIndex: firstIndex    // firstIndex 返回的是第一个catergory就满足的第一个Index,即为rowIndex开始于第几行。
            })
          }
        })
        this.indexInfoList = arr
        for (let i = 0; i < arr.length; i++){
          this.axios.post('/bdst/getowner', (response) => {
            if (this.tableData[arr[i].firstIndex].bdst === '00'){
              this.tableData[arr[i].firstIndex].bdst = '中心实验室' + ':' + response.obj;
            } else {
              var bd = this.tableData[arr[i].firstIndex].bdst;
              var bd1 = bd.slice(0,1);
              var bd2 = bd.slice(1,2);
              var bdqc = bd1 + '标' + bd2 + '工区' + ':' + response.obj;
              this.tableData[arr[i].firstIndex].bdst = bdqc;
            }
          },{
            bdst: this.tableData[arr[i].firstIndex].bdst
          })
        }
      },{
        time: this.value2
      })
    },
    selequepm(){
      this.axios.post("/videoquestion/selectpm", (response)=>{
        this.tableData = response.obj
        let arr = []
        this.tableData.forEach(ele => {
          let firstIndex = this.tableData.findIndex(item => {
            return item.bdst === ele.bdst   // 当category相同的时候，返回第一个相同的Index 赋值给 firstIndex
          })
          if (arr.findIndex(item => {
            return item.firstIndex === firstIndex
          }) === -1) {
            arr.push({
              length: this.tableData.filter(item => {
                return item.bdst === ele.bdst    //利用数组的filter方法，过滤出相同category的数组的长度。数组长度-即为跨多少行
              }).length,
              firstIndex: firstIndex    // firstIndex 返回的是第一个catergory就满足的第一个Index,即为rowIndex开始于第几行。
            })
          }
        })
        this.indexInfoList = arr
        for (let i = 0; i < arr.length; i++){
          this.axios.post('/bdst/getowner', (response) => {
            if (this.tableData[arr[i].firstIndex].bdst === '00'){
              this.tableData[arr[i].firstIndex].bdst = '中心实验室' + ':' + response.obj;
            } else {
              var bd = this.tableData[arr[i].firstIndex].bdst;
              var bd1 = bd.slice(0,1);
              var bd2 = bd.slice(1,2);
              var bdqc = bd1 + '标' + bd2 + '工区' + ':' + response.obj;
              this.tableData[arr[i].firstIndex].bdst = bdqc;
            }
          },{
            bdst: this.tableData[arr[i].firstIndex].bdst
          })
        }
      },{
        time: this.value2
      })
    }
  }
}
</script>

<style scoped>
</style>
