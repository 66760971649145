<!--对接海康SDK，查询视频监控服务器，在线率-->
<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">上报监控问题</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <Indexheader></Indexheader>
  </div>
</template>

<script>
import Indexheader from '@/views/cream/indexheader'
import Indexmain from '@/views/cream/indexmain'
import Header from "@/views/header";
export default {
  name: "index",
  components: {Indexmain, Indexheader, Header},
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  },
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>
